import { useSearchParams } from 'react-router';
import { ComboBox, Item } from '@venncity/block';
import { useAppLoaderData } from '~/utils/common';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

export function CommunityCombobox({ className }: { className?: string }) {
  const appLoaderData = useAppLoaderData();
  const searchNavigate = useSearchNavigate();
  const [urlSearchParams] = useSearchParams();
  const communityId = urlSearchParams.get(GlobalSearchParam.CommunityId);
  return (
    <ComboBox
      className={className}
      defaultSelectedKey={communityId || appLoaderData.selectedCommunity.id}
      items={appLoaderData.user.managedCommunities}
      label="Community"
      name="community-id"
      onSelectionChange={(value) => {
        searchNavigate({ communityId: value?.toString() });
      }}
      placeholder="Community">
      {(community) => <Item key={community.id}>{community.displayName}</Item>}
    </ComboBox>
  );
}
